import { Helmet } from 'react-helmet'
import {
	RiArrowRightLine,
	RiCodeSSlashFill,
	RiCodepenFill,
	RiDatabase2Fill,
	RiFlowChart,
	RiInboxFill,
	RiLeafFill,
	RiMistFill,
	RiTerminalBoxFill,
} from 'react-icons/ri'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import React from 'react'

import MarqueeProduct from 'modules/home/components/MarqueeProduct'
import Page from 'shared/components/page/Page'

import axa from 'assets/svg/axa.svg'
import bpi from 'assets/svg/bpi.svg'
import mazars from 'assets/svg/mazars.svg'
import sg from 'assets/svg/sg.svg'
import shift from 'assets/svg/shift.svg'

import careers from 'modules/careers/careers.json'

import * as Styled from 'modules/home/components/__styles__/HomePage.styles'

import gd_c from 'assets/images/gd_c.jpg'

const ConsultingServicePage = () => {
	const navigate = useNavigate()

	const container = {
		hidden: { opacity: 1, scale: 0 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				delayChildren: 0.3,
				staggerChildren: 0.2,
			},
		},
	}

	const item = {
		hidden: { y: 20, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	}

	const descriptionItems = [
		{
			title: 'Data transformation',
			description:
				'Data is at the heart of everything an enterprise aspires to do. Make your data more valuable on the cloud.',
			icon: <RiDatabase2Fill className="icon" />,
		},
		{
			title: 'AI & advanced analytics.AI',
			description: 'AI solutions that deliver game-changing results for your business, fast.',
			icon: <RiTerminalBoxFill className="icon" />,
		},
		{
			title: 'Content analytics',
			description: 'Unlock the value in unstructured data, text, images with analytics and machine learning.',
			icon: <RiMistFill className="icon" />,
		},
		{
			title: 'API Connects',
			description: 'Rapidly generate insights to accelerate decision-making and speed-to-value.',
			icon: <RiCodeSSlashFill className="icon" />,
		},
		{
			title: 'Modern data architecture',
			description: 'Unlock new sources of value and growth.',
			icon: <RiInboxFill className="icon" />,
		},
		{
			title: 'Business Process Services',
			description: 'Reinvent business performance, delivering sustainable value through intelligent operations.',
			icon: <RiFlowChart className="icon" />,
		},
		{
			title: 'Data modernization',
			description: 'Transform your data on any cloud, including application and infrastructure partners',
			icon: <RiCodepenFill className="icon" />,
		},
		{
			title: 'Visualization at scale',
			description: 'User generated content in real-time will have multiple touchpoints for offshoring.',
			icon: <RiLeafFill className="icon" />,
		},
	]

	return (
		<Page id="consulting_service">
			<Helmet>
				<title>Gnomon Digital - Consulting Service</title>
				<meta name="description" content="Consulting Service Data and Analytics consulting" />
				<link rel="canonical" href="https://www.gnomondigital.com/" />
			</Helmet>
			<div
				style={{ backgroundImage: `url(${gd_c})` }}
				className="bg-no-repeat sm:mt-[-84px] mt-[-46px] sm:bg-[100%] bg-[length:1440px] bg-[#2a35a2]"
			>
				<div className="mt-12 max-w-7xl layout sm:py-16 py-8">
					<div className="sm:pb-6 pb-4">
						<div className="fonts-small-yellow mb-1">Consulting Service</div>
						<div className="max-w-[410px] fonts-h1-white">Data and Analytics consulting</div>
						<div className="max-w-lg fonts-h5-white mt-4 sm:mt-6 space-y-4">
							<div>
								Our Consulting team is ready to help you to deal with data and build up your own AI infrastructure.
							</div>
							<div>
								Our management consulting services focus on our clients' most critical issues and opportunities:
								strategy, marketing, organization, operations, technology, transformation, digital, advanced analytics,
								corporate finance, mergers & acquisitions and sustainability across all industries and geographies.
							</div>
							<div>
								We bring deep, functional expertise, but are known for our holistic perspective: we capture value across
								boundaries and between the silos of any organization. We have proven a multiplier effect from optimizing
								the sum of the parts, not just the individual pieces.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-white text-center">
				<div className="max-w-7xl sm:py-16 py-8 layout">
					<div className="fonts-h2 sm:mb-16 mb-8">Capabilities</div>
					<motion.ul
						variants={container}
						initial="hidden"
						animate="visible"
						className="grid grid-cols-4 items-center justify-between sm:gap-12 gap-8"
					>
						{descriptionItems.map(({ icon, title, description }, i) => (
							<motion.li
								key={i}
								className="mx-auto sm:col-span-1 col-span-4 inline-block w-full text-center max-w-[233px]"
								variants={item}
							>
								<div>{icon}</div>
								<div className="my-4 fonts-h3">{title}</div>
								<div className="fonts-body mb-4">{description}</div>
							</motion.li>
						))}
					</motion.ul>
				</div>
			</div>

			<div className="bg-alice_blue">
				<div className="sm:py-16 py-8 layout grid grid-cols-3 gap-8">
					<div className="sm:col-span-1 col-span-3 space-y-3">
						<div className="fonts-h2">60x</div>
						<div className="fonts-body">
							Acceleration in data delivery time via advanced data engineering and data integration¹
						</div>
					</div>
					<div className="sm:col-span-1 col-span-3 space-y-3">
						<div className="fonts-h2">86-158%</div>
						<div className="fonts-body">Increase in ROI due to self-service data consumption and collaboration²</div>
					</div>
					<div className="sm:col-span-1 col-span-3 space-y-3">
						<div className="fonts-h2">430% </div>
						<div className="fonts-body">
							Productivity improvement across your teams utilizing AI and machine learning technologies¹
						</div>
					</div>
				</div>
			</div>

			<div className="bg-white text-center">
				<div className="sm:py-16 py-8 layout">
					<div className="fonts-h2 mb-8">Our customers and partners</div>
					<div className="flex justify-center items-baseline flex-wrap space-y-6 space-x-12 sm:pb-16 pb-10">
						<img src={bpi} alt="bpi logo" className="h-[44px]" />
						<img src={axa} alt="axa logo" className="h-[44px]" />
						<img src={shift} alt="shift logo" className="h-[44px]" />
						<img src={sg} alt="sg logo" className="h-[44px]" />
						<img src={mazars} alt="mazars logo" className="h-[44px]" />
					</div>
				</div>
			</div>

			<div className="bg-white text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Case study</div>
				</div>

				<div className="sm:pb-16 pb-8 layout max-w-7xl">
					<MarqueeProduct />
				</div>
			</div>

			<div className="bg-alice_blue text-center layout max-w-7xl sm:py-8 py-4">
				<div className="max-w-6xl">
					<div className="fonts-h2 sm:my-8 my-4">Rejoignez-nous pour construire l'avenir de l'ingénierie IA</div>
					<div className="max-w-3xl mx-auto mt-4 fonts-body">
						Chez Gnomon Digital, vous collaborerez avec une équipe diversifiée et dynamique d'experts qui partagent
						votre passion, offrant un environnement où l'innovation fleurit et où vos idées peuvent avoir un véritable
						impact.
					</div>
					<table className="w-full border-small bg-white sm:mb-16 mb-8">
						<tbody>
							{careers.data.slice(0, 3).map(({ title, location, type, key }) => (
								<tr
									onClick={() => navigate(`/careers/${key}`)}
									key={key}
									className="cursor-pointer  border border-solid border-[#EAEBF0]"
								>
									<td className="flex items-center justify-between py-5 sm:px-8 px-4">
										<div className="flex-row sm:flex items-center justify-between">
											<div className="mr-8 text-left">
												<div className="fonts-h5-gray">{title}</div>
												<div className="space-x-4 text-gray-50 font-sans leading-6 font-medium">
													<span>{location}</span>
													<span>{type}</span>
												</div>
											</div>
											<div>
												<svg width="55" height="28" viewBox="0 0 55 28" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect width="55" height="28" rx="5" fill="#F7FAFE" />
													<path
														d="M21.3366 8.81818V19H19.9247L14.7493 11.5327H14.6548V19H13.1186V8.81818H14.5405L19.7209 16.2955H19.8153V8.81818H21.3366ZM26.6495 19.1541C25.8971 19.1541 25.2491 18.9934 24.7056 18.6719C24.1653 18.3471 23.7477 17.8913 23.4527 17.3047C23.1611 16.7147 23.0152 16.0237 23.0152 15.2315C23.0152 14.4493 23.1611 13.7599 23.4527 13.1634C23.7477 12.5668 24.1587 12.1011 24.6857 11.7663C25.216 11.4316 25.8358 11.2642 26.5451 11.2642C26.9759 11.2642 27.3935 11.3355 27.7979 11.478C28.2023 11.6205 28.5652 11.8442 28.8867 12.1491C29.2082 12.4541 29.4617 12.8501 29.6473 13.3374C29.8329 13.8213 29.9257 14.4096 29.9257 15.1023V15.6293H23.8554V14.5156H28.4691C28.4691 14.1245 28.3895 13.7782 28.2304 13.4766C28.0713 13.1716 27.8476 12.9313 27.5593 12.7557C27.2742 12.58 26.9395 12.4922 26.555 12.4922C26.1374 12.4922 25.7728 12.5949 25.4613 12.8004C25.153 13.0026 24.9144 13.2678 24.7453 13.5959C24.5796 13.9207 24.4968 14.2737 24.4968 14.6548V15.5249C24.4968 16.0353 24.5863 16.4695 24.7652 16.8274C24.9475 17.1854 25.2011 17.4588 25.5259 17.6477C25.8507 17.8333 26.2302 17.9261 26.6644 17.9261C26.9461 17.9261 27.203 17.8864 27.435 17.8068C27.667 17.724 27.8675 17.6013 28.0365 17.4389C28.2056 17.2765 28.3348 17.076 28.4243 16.8374L29.8313 17.0909C29.7186 17.5052 29.5164 17.8681 29.2248 18.1797C28.9364 18.4879 28.5735 18.7282 28.136 18.9006C27.7018 19.0696 27.2063 19.1541 26.6495 19.1541ZM33.1169 19L30.8698 11.3636H32.406L33.9024 16.9716H33.977L35.4784 11.3636H37.0147L38.5061 16.9467H38.5807L40.0672 11.3636H41.6034L39.3613 19H37.8449L36.2938 13.4865H36.1794L34.6283 19H33.1169Z"
														fill="#3B72DF"
													/>
												</svg>
											</div>
										</div>
										<RiArrowRightLine className="w-5 h-5 text-gray-950" />
									</td>
								</tr>
							))}
						</tbody>
					</table>
					<Styled.PrimaryButton className="flex items-center mx-auto" onClick={() => navigate(`/careers`)}>
						<span className="ml-1.5">Plus de Postes</span>
						<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
								stroke="black"
								strokeWidth="1.67"
								strokeLinecap="round"
							/>
						</svg>
					</Styled.PrimaryButton>
				</div>
			</div>
		</Page>
	)
}

export default ConsultingServicePage
