import { Helmet } from 'react-helmet'
import React from 'react'

import MarqueeProduct from 'modules/home/components/MarqueeProduct'
import TeamsContent from 'modules/home/components/TeamsContent'

import Page from 'shared/components/page/Page'

import axa from 'assets/svg/axa.svg'
import bg_1 from 'assets/svg/bg-1.svg'
import bg_2 from 'assets/svg/bg-2.png'
import bpi from 'assets/svg/bpi.svg'
import mazars from 'assets/svg/mazars.svg'
import sg from 'assets/svg/sg.svg'
import shift from 'assets/svg/shift.svg'

import gd_left_1 from 'assets/images/gd_left_1.jpg'

const AboutUsPage = () => {
	return (
		<Page id="about_us">
			<Helmet>
				<title>Gnomon Digital - À Propos de Nous</title>
				<meta
					name="description"
					content="Découvrez les solutions de Gnomon Digital : des experts en données et en cloud native, offrant des solutions tout-en-un, sécurisées et évolutives. Nos services intègrent les technologies de data mesh et d'IA générative, adaptées aux besoins spécifiques de votre entreprise pour une transformation digitale réussie."
				/>
				<link rel="canonical" href="https://www.gnomondigital.com/" />
			</Helmet>
			<div
				style={{ backgroundImage: `url(${gd_left_1})`, backgroundPosition: 'center' }}
				className="bg-no-repeat sm:bg-[length:1440px] bg-[length:900px]"
			>
				<div className="mt-12 max-w-7xl layout">
					<div className="sm:pb-12 pb-8">
						<div className="fonts-small-blue mb-1">À Propos de Nous</div>
						<div className="max-w-[410px] fonts-h1">
							L'histoire de Gnomon Digital : Une Expertise Technologique Avancée
						</div>
						<div className="max-w-lg fonts-h5 mt-4 mb-8 sm:mt-6">
							Gnomon Digital est né de l'union d'experts en technologie passionnés, avec une mission claire : intégrer
							des solutions technologiques avancées pour transformer les entreprises et la vie quotidienne. Notre équipe
							est composée de professionnels chevronnés dans les domaines de la data, de l'IA et du cloud, qui partagent
							une compréhension approfondie des tendances actuelles et futures en matière de technologie.
						</div>
					</div>
				</div>
			</div>

			<div className="bg-alice_blue text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">
						Notre mission : Rendre l'IA et le Machine Learning plus accessibles et durables
					</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8">
						Nous œuvrons pour autonomiser nos clients avec le ML, facilitant le développement d'applications
						intelligentes. Notre approche simplifie le déploiement de l'IA sur divers appareils, accessible sans
						compétences spécialisées.
					</div>
					<img src={bg_1} className="w-full" alt="build better technology" />
				</div>
			</div>
			<div className="bg-white text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Nous envisageons le business autrement</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8">
						Les données sont omniprésentes et proviennent de sources variées. Pour devenir une entreprise pilotée par
						les données, les bons outils et les bonnes personnes sont essentiels. Nos services de conseil et nos
						produits autonomes garantissent une gestion fluide des données et du cloud, pour des opérations commerciales
						sans heurt.
					</div>
					<img src={bg_2} className="w-full max-w-[480px]" alt="We think business differently" />
				</div>
			</div>

			<div className="bg-alice_blue text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Nos services et produits</div>
					<div className="mt-4 fonts-body">
						Nous offrons des services de conseil experts pour les infrastructures de données et d'IA, et aidons les
						entreprises et les startups à déployer des modèles de ML accélérés. Notre approche vise à faciliter
						l'adoption de l'IA et du Machine Learning dans divers secteurs, en transformant les défis en opportunités
						innovantes.
					</div>
				</div>

				<div className="sm:pb-16 pb-8 layout max-w-7xl">
					<MarqueeProduct />
				</div>
			</div>

			<div className="bg-white text-center">
				<div className="sm:py-16 py-8 layout">
					<div className="fonts-h2 mb-8">Nos clients et partenaires</div>
					<div className="flex justify-center items-baseline flex-wrap space-y-6 space-x-12 sm:pb-16 pb-10">
						<img src={bpi} alt="bpi logo" className="h-[44px]" />
						<img src={axa} alt="axa logo" className="h-[44px]" />
						<img src={shift} alt="shift logo" className="h-[44px]" />
						<img src={sg} alt="sg logo" className="h-[44px]" />
						<img src={mazars} alt="mazars logo" className="h-[100px]" />
					</div>
				</div>
			</div>

			<TeamsContent />
		</Page>
	)
}

export default AboutUsPage
