import { RiArrowRightLine } from 'react-icons/ri'
import { Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import React from 'react'

import Page from 'shared/components/page/Page'

import careers from './careers'

const CareersPage = () => {
	const navigate = useNavigate()

	return (
		<Page id="CareersPage">
			<div className="bg-white text-center layout max-w-7xl">
				<div className="sm:py-16 py-8 ">
					<div className="fonts-h2">Rejoignez-nous pour construire l'avenir de l'ingénierie IA</div>
					<div className="max-w-3xl mx-auto mt-4 fonts-body sm:mb-16 mb-8">
						Chez Gnomon Digital, vous collaborerez avec une équipe diversifiée et dynamique d'experts qui partagent
						votre passion, offrant un environnement où l'innovation fleurit et où vos idées peuvent avoir un véritable
						impact.
					</div>
				</div>
				<div className="grid grid-cols-4 gap-8">
					<div className="sm:col-span-1 col-span-4">
						<div className="p-8 border-small space-y-4 text-left">
							<div>
								<div className="fonts-small mb-2">Job Position</div>
								<Select
									defaultValue="all"
									className="w-full"
									options={[
										{ value: 'all', label: 'All Position' },
										{ value: 'fullstack', label: 'Full Stack' },
									]}
								/>
							</div>
							<div>
								<div className="fonts-small mb-2">Location</div>
								<Select
									defaultValue="paris"
									className="w-full"
									options={[
										{ value: 'paris', label: 'Paris' },
										{ value: 'lyon', label: 'Lyon' },
									]}
								/>
							</div>
							<div>
								<div className="fonts-small mb-2">Work type</div>
								<Select
									defaultValue="site"
									className="w-full"
									options={[
										{ value: 'site', label: 'On-site' },
										{ value: 'remote', label: 'Hybrid' },
									]}
								/>
							</div>
						</div>
					</div>
					<div className="sm:col-span-3 col-span-4">
						<table className="w-full border-small">
							<tbody>
								{careers.data.map(({ title, location, type, key }) => (
									<tr
										onClick={() => navigate(`/careers/${key}`)}
										key={key}
										className="cursor-pointer border border-solid border-[#EAEBF0]"
									>
										<td className="flex items-center justify-between py-5 sm:px-8 px-4">
											<div className="flex-row sm:flex items-center justify-between">
												<div className="mr-8 text-left">
													<div className="fonts-h5-gray">{title}</div>
													<div className="space-x-4 text-gray-50 font-sans leading-6 font-medium">
														<span>{location}</span>
														<span>{type}</span>
													</div>
												</div>
												<div>
													<svg
														width="55"
														height="28"
														viewBox="0 0 55 28"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect width="55" height="28" rx="5" fill="#F7FAFE" />
														<path
															d="M21.3366 8.81818V19H19.9247L14.7493 11.5327H14.6548V19H13.1186V8.81818H14.5405L19.7209 16.2955H19.8153V8.81818H21.3366ZM26.6495 19.1541C25.8971 19.1541 25.2491 18.9934 24.7056 18.6719C24.1653 18.3471 23.7477 17.8913 23.4527 17.3047C23.1611 16.7147 23.0152 16.0237 23.0152 15.2315C23.0152 14.4493 23.1611 13.7599 23.4527 13.1634C23.7477 12.5668 24.1587 12.1011 24.6857 11.7663C25.216 11.4316 25.8358 11.2642 26.5451 11.2642C26.9759 11.2642 27.3935 11.3355 27.7979 11.478C28.2023 11.6205 28.5652 11.8442 28.8867 12.1491C29.2082 12.4541 29.4617 12.8501 29.6473 13.3374C29.8329 13.8213 29.9257 14.4096 29.9257 15.1023V15.6293H23.8554V14.5156H28.4691C28.4691 14.1245 28.3895 13.7782 28.2304 13.4766C28.0713 13.1716 27.8476 12.9313 27.5593 12.7557C27.2742 12.58 26.9395 12.4922 26.555 12.4922C26.1374 12.4922 25.7728 12.5949 25.4613 12.8004C25.153 13.0026 24.9144 13.2678 24.7453 13.5959C24.5796 13.9207 24.4968 14.2737 24.4968 14.6548V15.5249C24.4968 16.0353 24.5863 16.4695 24.7652 16.8274C24.9475 17.1854 25.2011 17.4588 25.5259 17.6477C25.8507 17.8333 26.2302 17.9261 26.6644 17.9261C26.9461 17.9261 27.203 17.8864 27.435 17.8068C27.667 17.724 27.8675 17.6013 28.0365 17.4389C28.2056 17.2765 28.3348 17.076 28.4243 16.8374L29.8313 17.0909C29.7186 17.5052 29.5164 17.8681 29.2248 18.1797C28.9364 18.4879 28.5735 18.7282 28.136 18.9006C27.7018 19.0696 27.2063 19.1541 26.6495 19.1541ZM33.1169 19L30.8698 11.3636H32.406L33.9024 16.9716H33.977L35.4784 11.3636H37.0147L38.5061 16.9467H38.5807L40.0672 11.3636H41.6034L39.3613 19H37.8449L36.2938 13.4865H36.1794L34.6283 19H33.1169Z"
															fill="#3B72DF"
														/>
													</svg>
												</div>
											</div>
											<RiArrowRightLine className="w-5 h-5 text-gray-950" />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</Page>
	)
}

export default CareersPage
