import { createRoot } from 'react-dom/client'
import { hydrate } from 'react-dom'
import React from 'react'

import Root from 'shared/components/Root'

import 'antd/dist/reset.css'

import './index.css'

const rootElement = document.getElementById('root')

if (rootElement.hasChildNodes()) {
	hydrate(<Root />, rootElement)
} else {
	createRoot(rootElement).render(<Root />)
}
