import React from 'react'

const MarqueeTeams = () => {
	const teams = [
		{
			title: 'Junwen YIN',
			description: 'Expert Cloud Engineer',
			link: '/',
			img: '/static/images/Junwen_YIN.jpg',
		},
		{
			title: 'Minh DOBOI',
			description: 'Expert Data Engineer',
			link: '/',
			img: '/static/images/Minh_DOBOI.jpg',
		},
		{
			title: 'Angélique FILLEAU',
			description: 'Chief Operating Officer',
			link: '/',
			img: '/static/images/angélique_filleau.jpeg',
		},
		{
			title: 'Nacima YAHIATENE',
			description: 'Data Engineer',
			link: '/',
			img: '/static/images/Nacima_YAHIATENE.jpeg',
		},
		{
			title: 'Wenkun LI',
			description: 'Expert AI Engineer',
			link: '/',
			img: '/static/images/Wenkun_LI.jpeg',
		},
		{
			title: 'Inès BEN AMEUR',
			description: 'AI Engineer',
			link: '/',
			img: '/static/images/INES_BEN_AMEUR.JPG',
		},
	]

	const renderTeams = () =>
		teams.map(({ img, title, description }, i) => (
			<div key={i} className="max-w-[160px] text-left">
				<img src={img} alt={title} className="max-w-[160px] max-h-[160px] grayscale" />
				<div className="text-gray-950 font-sans leading-6 font-normal mt-2">{title}</div>
				<div className="fonts-small">{description}</div>
			</div>
		))

	return (
		<div className="marquee">
			<div className="marquee__group">{renderTeams()}</div>
			<div aria-hidden="true" className="marquee__group">
				{renderTeams()}
			</div>
		</div>
	)
}

export default MarqueeTeams
