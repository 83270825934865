import { api } from './index'

export const userApi = api.injectEndpoints({
	endpoints: (build) => ({
		getUUID: build.query({ query: () => '/uuid' }),

		postCV: build.mutation({
			query: ({ formData, uuid }) => {
				return {
					url: `/upload_file/${uuid}`,
					method: 'POST',
					body: formData,
					headers: { 'Content-Type': undefined },
				}
			},
		}),

		postCandidateForm: build.mutation({
			query: ({ metadata, uuid }) => ({ url: `/upload_metadata/${uuid}`, method: 'POST', body: { ...metadata, uuid } }),
		}),
	}),
})

export const { useGetUUIDQuery, usePostCVMutation, usePostCandidateFormMutation } = userApi
