import { Button as _Button, Form as _Form } from 'antd'
import styled from '@xstyled/styled-components'

import Page from 'shared/components/page/Page'

import gd_left from 'assets/images/gd_left.jpg'

export const Root = styled(Page)``

export const Container = styled.div`
	background-image: ${() => `url(${gd_left})`};
	background-repeat: no-repeat;
	background-size: 900px;
	background-position: -470px -140px;

	@media (min-width: 640px) {
		background-position: 100px -150px;
		background-size: 1440px;
	}
`

export const PrimaryButton = styled(_Button)`
	height: 46px;
	padding: 12px 18px;
	background: #f8b211;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.04);
	border-radius: 6px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: #000000;
	&:hover {
		background: rgb(248 178 17 / 90%) !important;
	}
`

export const Button = styled(_Button)`
	height: 46px;
	background: #ffffff;
	border: 1px solid #dae0e6;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.04);
	border-radius: 6px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: #000000;
`

export const SecondaryButton = styled(_Button)`
	height: 46px;
	padding: 12px 18px;
	background: #19459b;
	box-shadow: 0px 1px 2px 0px #1018280a;
	border-radius: 6px;
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	color: white;
	display: flex;
	align-items: center;
	&:hover {
		background: #19459be6 !important;
		color: white !important;
	}
`

export const Form = styled(_Form)`
	padding: 32px;
	background: #fafbfc;
	border: 1px solid #eaebf0;
	box-shadow: 0 1px 2px rgba(16, 24, 40, 0.04);
	border-radius: 5px;

	.ant-checkbox {
		margin-top: 2px;
	}
	.ant-upload {
		button {
			height: 40px;
			font-weight: 600;
		}
	}
`
