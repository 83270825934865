import { Helmet } from 'react-helmet'
import {
	RiBarChartHorizontalFill,
	RiDatabase2Fill,
	RiDatabase2Line,
	RiFlowChart,
	RiHardDrive2Fill,
	RiTerminalBoxFill,
} from 'react-icons/ri'
import { motion } from 'framer-motion'
import React from 'react'

import Page from 'shared/components/page/Page'

import * as Styled from 'modules/home/components/__styles__/HomePage.styles'

import gd_nubula from 'assets/images/gd_nebula.jpg'

const NebulaPage = () => {
	const container = {
		hidden: { opacity: 1, scale: 0 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: {
				delayChildren: 0.3,
				staggerChildren: 0.2,
			},
		},
	}

	const item = {
		hidden: { y: 20, opacity: 0 },
		visible: {
			y: 0,
			opacity: 1,
		},
	}

	const descriptionItems = [
		{
			title: 'Data transfer',
			description:
				'Data is at the heart of everything an enterprise aspires to do. Make your data more valuable on the cloud.',
			icon: <RiDatabase2Fill className="icon" />,
		},
		{
			title: 'Data ingestion',
			description: 'AI solutions that deliver game-changing results for your business, fast.',
			icon: <RiDatabase2Line className="icon" />,
		},
		{
			title: 'Data Management',
			description: 'Unlock the value in unstructured data, text, images with analytics and machine learning.',
			icon: <RiHardDrive2Fill className="icon" />,
		},
		{
			title: 'Schedule a batch job with Airflow',
			description: 'Rapidly generate insights to accelerate decision-making and speed-to-value.',
			icon: <RiTerminalBoxFill className="icon" />,
		},
		{
			title: 'Data Visualization with Metabase',
			description: 'Unlock new sources of value and growth.',
			icon: <RiBarChartHorizontalFill className="icon" />,
		},
		{
			title: 'Machine Learning modeling with Jupyterhub',
			description: 'Reinvent business performance, delivering sustainable value through intelligent operations.',
			icon: <RiFlowChart className="icon" />,
		},
	]

	return (
		<Page id="consulting_service">
			<Helmet>
				<title>Nebulas AI</title>
				<meta name="description" content="Nebulas AI" />
				<link rel="canonical" href="https://www.gnomondigital.com/" />
			</Helmet>
			<div className="relative bg-[#1d1f21] sm:mt-[-84px] mt-[-46px] h-[700px]">
				<img src={gd_nubula} alt="nubula" className="w-full absolute" />
				<div className="mt-12 max-w-7xl layout sm:py-16 py-8 absolute">
					<div className="sm:pb-6 pb-4">
						<div className="fonts-small-yellow mb-1">Nebulas AI</div>
						<div className="max-w-[410px] fonts-h1-white">Accelerated ML models deployment</div>
						<div className="max-w-lg fonts-h5-white mt-4 sm:mt-6 space-y-4">
							<div>
								Our Consulting team is ready to help you to deal with data and build up your own AI infrastructure. Our
								Consulting team is ready to help you to deal with data and build up your own AI infrastructure.
							</div>
							<div>
								Our management consulting services focus on our clients' most critical issues and opportunities:
								strategy, marketing, organization, operations, technology, transformation, digital, advanced analytics,
								corporate finance, mergers & acquisitions and sustainability across all industries and geographies.
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-white text-center">
				<div className="max-w-7xl sm:py-16 py-8 layout">
					<div className="fonts-h2 sm:mb-16 mb-8">Multiple scenarios and boosted performance</div>
					<motion.ul
						variants={container}
						initial="hidden"
						animate="visible"
						className="grid grid-cols-3 items-center justify-between sm:gap-12 gap-8"
					>
						{descriptionItems.map(({ icon, title, description }, i) => (
							<motion.li
								key={i}
								className="mx-auto sm:col-span-1 col-span-4 inline-block w-full text-center max-w-[233px]"
								variants={item}
							>
								<div>{icon}</div>
								<div className="my-4 fonts-h3">{title}</div>
								<div className="fonts-body mb-4">{description}</div>
							</motion.li>
						))}
					</motion.ul>
				</div>
			</div>

			<div className="bg-white layout max-w-7xl sm:pb-16 pb-8 space-y-4 sm:space-y-12">
				<div className="flex-row sm:flex justify-between items-center">
					<div className="max-w-[456px]">
						<div className="fonts-small-blue">Consulting Service</div>
						<div className="text-[1.5rem] font-sans text-gray-950 font-bold mt-1 sm:mb-2 mb-1">
							Data and Analytics consulting
						</div>
						<div className="fonts-body sm:mb-10 mb-2">
							We offer comprehensive data consulting services that assist in aligning your data strategy with a
							forward-thinking architecture, including governance and self-management considerations. Our team
							incorporates data into essential workflows, strategically integrating AI and automation to enhance
							specific points of impact.
						</div>
						<Styled.PrimaryButton
							onClick={() => window.open('https://gpt.nebulas.ai/', '_blank').focus()}
							type="primary"
							className="flex items-center"
						>
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
					<div>
						<img src="/static/images/dashboard.png" alt="dashboard" className="w-full max-w-[480px] max-h-[480px]" />
					</div>
				</div>
				<div className="flex-row sm:flex justify-between items-center">
					<div>
						<img src="/static/images/dashboard.png" alt="dashboard" className="w-full max-w-[480px] max-h-[480px]" />
					</div>
					<div className="max-w-[456px]">
						<div className="fonts-small-blue">Consulting Service</div>
						<div className="text-[1.5rem] font-sans text-gray-950 font-bold mt-1 sm:mb-2 mb-1">
							Data and Analytics consulting
						</div>
						<div className="fonts-body sm:mb-10 mb-2">
							We offer comprehensive data consulting services that assist in aligning your data strategy with a
							forward-thinking architecture, including governance and self-management considerations. Our team
							incorporates data into essential workflows, strategically integrating AI and automation to enhance
							specific points of impact.
						</div>
						<Styled.PrimaryButton
							onClick={() => window.open('https://gpt.nebulas.ai/', '_blank').focus()}
							type="primary"
							className="flex items-center"
						>
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
				</div>
			</div>

			<div className="bg-alice_blue text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Empower Business with adapted technology</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8">
						Data is all around us, coming from many different sources like sensors, apps, and businesses. To become a
						data-driven organization, it's important to have the right tools and people to manage data which requires
						changing the way things are done and learning new skills. We offer consulting service and stand alone
						product to ensure your daily business can run smoothly with data and cloud enabled.
					</div>
					<img src="/static/images/dashboard.png" alt="dashboard" className="w-full max-w-[480px] max-h-[480px]" />
				</div>
			</div>
		</Page>
	)
}

export default NebulaPage
