import { useEffect } from 'react'

const HubSpotScript = () => {
	useEffect(() => {
		// Check if the script is already present
		const existingScript = document.getElementById('hs-script-loader')

		if (!existingScript) {
			const script = document.createElement('script')
			script.src = '//js-eu1.hs-scripts.com/143808395.js'
			script.id = 'hs-script-loader'
			script.async = true
			script.defer = true
			document.body.appendChild(script)
		}

		return () => {
			// Cleanup the script when the component unmounts
			if (existingScript) {
				existingScript.remove()
			}
		}
	}, [])

	return null // This component doesn't render anything
}

export default HubSpotScript
