import React from 'react'

import Page from 'shared/components/page/Page'

import bg_1 from 'assets/svg/bg-1.svg'

const DisclosurePage = () => {
	return (
		<Page id="terms">
			<div className="bg-alice_blue text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Responsible Disclosure</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8 text-left">
						We appreciate your efforts in keeping our website secure. If you discover any security vulnerabilities or
						weaknesses, we encourage you to responsibly disclose them to us.
						<br />
						<br />
						1. Reporting: Please report any identified security vulnerabilities or weaknesses to our designated email
						address: <a href="mailto:contact@gnomondigital.com">contact@gnomondigital.com</a>.
						<br />
						<br />
						2. Scope: This responsible disclosure policy only applies to security vulnerabilities or weaknesses related
						to our website and services.
						<br />
						<br />
						3. Responsible Disclosure: We ask that you refrain from publicly disclosing any vulnerabilities before we
						have had a chance to investigate and address the issue.
						<br />
						<br />
						4. Legal Compliance: We will not take legal action against individuals who discover and report security
						vulnerabilities or weaknesses in accordance with this policy, as long as they act responsibly and in good
						faith.
						<br />
						<br />
						Remember, it's crucial to review and customise these policies based on your specific requirements, industry
						standards, and applicable laws.
						<br />
						<br />
					</div>
					<img src={bg_1} className="w-full" alt="build better technology" />
				</div>
			</div>
		</Page>
	)
}

export default DisclosurePage
