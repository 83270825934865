import React from 'react'

import Page from 'shared/components/page/Page'

import bg_2 from 'assets/svg/bg-2.png'

const PrivacyPolicyPage = () => {
	return (
		<Page id="terms">
			<div className="bg-alice_blue text-center">
				<div className="max-w-3xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Privacy Policy</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8 text-left">
						At Gnomon Digital, we are committed to protecting your privacy. This Privacy Policy explains how we collect,
						use, and disclose your personal information. By using our website, you consent to the terms of this Privacy
						Policy. <br />
						<br />
						1. Information Collection: We collect personal information, such as your name and email address, when you
						voluntarily submit it to us through our website or other communication channels. <br />
						<br />
						2. Use of Information: We use the collected information to communicate with you, provide our services,
						improve our website, and comply with legal obligations. <br />
						<br />
						3. Third-Party Disclosure: We may share your personal information with trusted third parties who assist us
						in operating our website and providing our services. We do not sell, trade, or transfer your personal
						information to outside parties without your consent. <br />
						<br />
						4. Security: We implement appropriate security measures to protect your personal information. However,
						please be aware that no data transmission over the internet or electronic storage method is 100% secure.
					</div>
					<img src={bg_2} className="w-full" alt="build better technology" />
				</div>
			</div>
		</Page>
	)
}

export default PrivacyPolicyPage
