import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import React from 'react'

import Page from 'shared/components/page/Page'

import blogs from 'assets/blog/blogs'

const BlogPage = () => {
	const navigate = useNavigate()

	const container = {
		hidden: { opacity: 1, scale: 0 },
		visible: {
			opacity: 1,
			scale: 1,
			transition: { delayChildren: 0.3, staggerChildren: 0.2 },
		},
	}

	return (
		<Page id="consulting_service">
			<div className="bg-white text-center">
				<div className="max-w-7xl sm:py-16 py-8 layout">
					<div className="fonts-h2">Blog</div>
					<div className="mt-4 fonts-body sm:mb-16 mb-8">the latest news and product updates from Gnomon Digital</div>
					<motion.ul variants={container} initial="hidden" animate="visible" className="sm:space-y-12 space-y-8">
						{blogs.data.map(({ key, introduction, title, author, date, category }, i) => (
							<motion.li
								key={i}
								onClick={() => navigate(`/blog/${key}`)}
								className="cursor-pointer inline-block w-full sm:space-x-16 space-x-4 flex items-center justify-between"
								variants={{ hidden: { y: 20, opacity: 0 }, visible: { y: 0, opacity: 1 } }}
							>
								<div className="text-left space-y-4">
									<div className="fonts-h4">{title}</div>
									<div className="space-x-4">
										<span className="fonts-small-blue">{category}</span>
										<span className="fonts-small-gary">{date}</span>
										<span className="fonts-small-gary">{author}</span>
									</div>
									<div className="sm:block hidden fonts-body">{introduction}</div>
								</div>
								<div>
									<img src={`static/images/blog-${i + 1}.svg`} alt="blog" />
								</div>
							</motion.li>
						))}
					</motion.ul>
				</div>
			</div>
		</Page>
	)
}

export default BlogPage
