import { Route, Routes, useLocation } from 'react-router-dom'
import React, { useLayoutEffect } from 'react'

import AboutUsPage from 'modules/AboutUsPage'
import BlogDetailsPage from 'modules/BlogDetailsPage'
import BlogPage from 'modules/BlogPage'
import CareersDetailsPage from 'modules/careers/CareersDetailsPage'
import CareersPage from 'modules/careers/CareersPage'
import ConsultingServicePage from 'modules/ConsultingServicePage'
import DisclosurePage from 'modules/DisclosurePage'
import HomePage from 'modules/home'
import HubSpotScript from 'shared/components/hubspot/HubSpotScript'
import NebulaPage from 'modules/NebulaPage'
import PrivacyPolicyPage from 'modules/PrivacyPolicyPage'
import TermsPage from 'modules/TermsPage'

import ErrorBoundary from 'shared/components/error/ErrorBoundary'
import Layout from 'shared/components/layout/Layout'
import NoMatchPage from 'shared/components/page/NoMatchPage'

const App = () => {
	const location = useLocation()

	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<>
			<HubSpotScript />
			<ErrorBoundary>
				<Routes>
					<Route path="/" element={<Layout />}>
						<Route index element={<HomePage />} />
						<Route path="consulting_service" element={<ConsultingServicePage />} />
						<Route path="nebula" element={<NebulaPage />} />
						<Route path="about_us" element={<AboutUsPage />} />
						<Route path="terms" element={<TermsPage />} />
						<Route path="privacy_policy" element={<PrivacyPolicyPage />} />
						<Route path="disclosure" element={<DisclosurePage />} />
						<Route path="careers">
							<Route index element={<CareersPage />} />
							<Route path=":id" element={<CareersDetailsPage />} />
						</Route>
						<Route path="blog">
							<Route index element={<BlogPage />} />
							<Route path=":id" element={<BlogDetailsPage />} />
						</Route>
						<Route path="*" element={<NoMatchPage />} />
					</Route>
				</Routes>
			</ErrorBoundary>
		</>
	)
}

export default App
