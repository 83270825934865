import { Layout } from 'antd'
import styled from '@xstyled/styled-components'

const { Footer: _Footer } = Layout

export const Root = styled(_Footer)`
	.ant-divider {
		border-color: white;
	}
`
