import { Link, useLocation } from 'react-router-dom'
import Page from 'shared/components/page/Page'
import React, { useEffect, useState } from 'react'
import rehypeRaw from 'rehype-raw'

import ReactMarkdown from 'react-markdown'
import blogs from 'assets/blog/blogs'

const BlogDetailsPage = () => {
	const [post, setPost] = useState()
	const location = useLocation()
	const id = location.pathname.split('/')[2]

	useEffect(() => {
		const blog = blogs.data.find((item) => item.key === id)

		import(`assets/blog/${blog.key}.md`).then((res) => {
			fetch(res.default)
				.then((res) => res.text())
				.then((res) => setPost(res))
		})
	}, [id])

	return (
		<Page id="consulting_service">
			<div className="max-w-7xl layout mt-6">
				<Link to={'/blog'}>Back To Blog</Link>
				<div className="bg-white">
					<div className="sm:py-10 py-8">
						<ReactMarkdown children={post} rehypePlugins={[rehypeRaw]} className="markdown" />
					</div>
				</div>
			</div>
		</Page>
	)
}

export default BlogDetailsPage
