import { Input, Layout, Typography } from 'antd'
import styled from '@xstyled/styled-components'

const { Header: _Header } = Layout

export const Root = styled(_Header)`
	background: ${({ $isConsulting, $isNebula }) => {
		if ($isConsulting) {
			return 'linear-gradient(135deg, #3d3343 0%, #242c8b 85.36%)'
		} else if ($isNebula) {
			return 'linear-gradient(135deg, rgb(29 31 33) 0%, rgb(31 31 27) 85.36%)'
		} else {
			return 'white'
		}
	}};
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 86px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #eaebf0;
	border-bottom: ${({ $isScrolled, $isConsulting, $isNebula }) =>
		$isScrolled && !$isConsulting && !$isNebula ? '1px solid #eaebf0' : 'none'};
	.ant-menu {
		flex: auto;
		justify-content: right;
		border-bottom: none;
	}
`

export const Title = styled(Typography.Title)`
	color: primary.7 !important;
	margin: 0 8px !important;
`

export const Search = styled(Input.Search)`
	max-width: 500px;
	border-radius: 16px;
`
