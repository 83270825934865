import React from 'react'

import academy from 'assets/images/academy.jpg'

import * as Styled from './__styles__/HomePage.styles'

const MarqueeProduct = () => {
	const products = [
		{
			title: 'CONSULTING SERVICE',
			description: 'Expert consulting for Data and AI Infrastructure',
			link: '/',
			img: '/static/images/service.png',
		},
		{
			title: 'MACHINE LEARNING',
			description: 'Accelerated ML models deployment',
			link: '/',
			img: '/static/images/machine.png',
		},
		{
			title: 'MACHINE LEARNING',
			description: 'Training and certification ML & data science',
			link: '/',
			img: academy,
		},
		{
			title: 'DATA ANALYSIS',
			description: 'Expert consulting for Data and AI Infrastructure',
			link: '/',
			img: '/static/images/data.png',
		},
		{
			title: 'CYBER SECURITY',
			description: 'Accelerated ML models deployment',
			link: '/',
			img: '/static/images/security.png',
		},
		{
			title: 'MACHINE LEARNING',
			description: 'Training and certification ML & data science',
			link: '/',
			img: '/static/images/machine_1.png',
		},
	]

	return (
		<div className="marquee">
			<div className="marquee__group">
				{products.map(({ img, title, description }, i) => (
					<div key={i} className="max-w-[288px]">
						<img src={img} alt={title} className="max-w-[288px] max-h-[288px]" />
						<div className="fonts-small-blue sm:mt-8 mt-6">{title}</div>
						<div className="fonts-h4 mt-2 mb-6">{description}</div>
						<Styled.PrimaryButton type="primary" className="flex items-center mx-auto">
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
				))}
			</div>
			<div aria-hidden="true" className="marquee__group">
				{products.map(({ img, title, description }, i) => (
					<div key={i} className="max-w-[288px]">
						<img src={img} alt={title} className="max-w-[288px] max-h-[288px]" />
						<div className="fonts-small-blue sm:mt-8 mt-6">{title}</div>
						<div className="fonts-h4 mt-2 mb-6">{description}</div>
						<Styled.PrimaryButton type="primary" className="flex items-center mx-auto">
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
				))}
			</div>
		</div>
	)
}

export default MarqueeProduct
