import { Helmet } from 'react-helmet'
import { Link, useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

import academy from 'assets/images/academy.jpg'
import axa from 'assets/svg/axa.svg'
import bpi from 'assets/svg/bpi.svg'
import mazars from 'assets/svg/mazars.svg'
import sg from 'assets/svg/sg.svg'
import shift from 'assets/svg/shift.svg'

import * as Styled from 'modules/home/components/__styles__/HomePage.styles'

import TeamsContent from './TeamsContent'

// import blue from 'shared/spline/blue.splinecode'
// import cube from 'shared/spline/cube.splinecode'

const HomePage = () => {
	const navigate = useNavigate()

	const [isSplineReady, setIsSplineReady] = useState(false)

	useEffect(() => {
		if (typeof window?.ResizeObserver === 'function' && !isSplineReady) {
			setIsSplineReady(true)
		}
	}, [isSplineReady])

	return (
		<Styled.Root id="home">
			<Helmet>
				<title>Gnomon Digital - Nebulas AI</title>
				<meta
					name="description"
					content="Gnomon Digital : Expertise et Innovation en Data et AI. Découvrez chez Gnomon Digital des solutions de data cloud-native et data mesh, conçues pour maximiser la valeur de vos données. Nous aidons nos clients à développer des plateformes AI avec MLOps et BI, et à intégrer des services mesh innovants avec Nebulas.ai. Notre équipe d'experts dédiée garantit un accompagnement personnalisé et une formation de pointe. Chez Gnomon Digital, nous fusionnons technologie avancée et développement personnel pour transformer vos données en succès stratégiques."
				/>
				<link rel="canonical" href="https://www.gnomondigital.com/" />
			</Helmet>
			<Styled.Container>
				<div className="mt-12 max-w-7xl layout">
					<div className="sm:mb-12 mb-8">
						<div className="max-w-[410px] fonts-h1">
							Optimiser la prise de décision grâce à des solutions basées sur l'IA
						</div>
						<div className="max-w-lg fonts-h5 mt-4 mb-8 sm:mt-6">
							Spécialisés dans la valorisation des données, nous offrons trois services essentiels : des plateformes
							cloud personnalisées, une formation et un accompagnement sur mesure, et des solutions d'IA innovantes pour
							une analyse efficace de vos données.
						</div>
						<div className="space-x-4">
							<Link to="/about_us">
								<Styled.PrimaryButton type="primary">En savoir plus</Styled.PrimaryButton>
							</Link>
							{/* <Styled.Button>Contact Us</Styled.Button> */}
						</div>
					</div>
					<div className="fonts-h5 mb-2">Ils nous font confiance</div>
					<div className="flex items-baseline flex-wrap space-y-8 space-x-8 sm:pb-16 pb-10">
						<img src={bpi} alt="bpi logo" />
						<img src={axa} alt="axa logo" />
						<img src={shift} alt="shift logo" />
						<img src={sg} alt="sg logo" className="h-[28px]" />
						<img src={mazars} alt="mazars logo" className="h-[80px]" />
					</div>
				</div>
			</Styled.Container>

			<div className="bg-alice_blue text-center">
				<div className="layout sm:py-16 py-8 max-w-3xl">
					<div className="fonts-h2">Améliorer les performances des entreprises grâce à la technologie appropriée</div>
					<div className="mt-4 fonts-body">
						Les données sont partout, provenant de diverses sources. Pour devenir une entreprise data-driven, les bons
						outils et les bonnes personnes sont cruciaux. Nos services de conseil et nos produits garantissent une
						gestion fluide des données et du cloud.
					</div>
				</div>
			</div>
			{/*<div className="bg-alice_blue text-center">*/}
			{/*	<div className="bg-white text-center">*/}
			{/*		<div className="max-w-7xl layout py-16 ">*/}
			{/*			<div className="fonts-h2">Build and deploy ML models ever faster</div>*/}
			{/*			<div className="mt-4 mb-16 fonts-body">*/}
			{/*				Gnomon Digital provides the total solution to build and deploy ML models and data analysis service for*/}
			{/*				enterprises and start-ups*/}
			{/*			</div>*/}
			{/*			{isSplineReady && (*/}
			{/*				<>*/}
			{/*					/!*<div className="w-96 h-96">*!/*/}
			{/*					/!*	<Spline scene={cube} />*!/*/}
			{/*					/!*</div>*!/*/}
			{/*					/!*<div className="w-96 h-96">*!/*/}
			{/*					/!*	<Spline scene={blue} />*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</>*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			<div className="bg-white layout max-w-7xl sm:py-16 py-8 space-y-4 sm:space-y-12">
				<div className="gap-4 flex flex-col-reverse sm:flex-row sm:flex sm:justify-between items-center space-y-4 sm:space-y-0">
					<div className="max-w-[556px]">
						<div className="fonts-small-blue">Centre d'expertise</div>
						<div className="text-[1.5rem] font-sans text-gray-950 font-bold mt-1 sm:mb-2 mb-1">Data, IA et Cloud</div>
						<div className="fonts-body sm:mb-10 mb-2">
							Alignez votre stratégie avec une architecture avant-gardiste, grâce à notre expertise en données. Nous
							développons des solutions clé en main pour une utilisation optimale de vos données, simplifiant la prise
							de décision et favorisant votre avancée stratégique. Nos services de conseil vous aident à exploiter et
							analyser efficacement vos données pour un impact maximal.
						</div>
						<Styled.PrimaryButton onClick={() => navigate('/about_us')} type="primary" className="flex items-center">
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
					<div>
						<img
							src="/static/images/service.png"
							alt="service"
							className="sm:max-h-[360px] sm:max-w-[360px] max-h-[240px] max-w-[240px] w-full"
						/>
					</div>
				</div>
				<div className="gap-4 flex flex-col sm:flex-row sm:justify-between items-center space-y-4 sm:space-y-0">
					<div>
						<img
							src="/static/images/machine.png"
							alt="service"
							className="sm:max-h-[360px] sm:max-w-[360px] max-h-[240px] max-w-[240px] w-full"
						/>
					</div>
					<div className="max-w-[556px]">
						<div className="fonts-small-blue nebulas-container">
							Nebulas.ai<span className="beta-label">beta</span>
						</div>
						<div className="text-[1.5rem] font-sans text-gray-950 font-bold mt-1 sm:mb-2 mb-1">
							Plateforme d'Assistance Intelligente
						</div>
						<div className="fonts-body sm:mb-10 mb-2">
							Notre plateforme unique offre des Assistants basés sur l'IA, exploitant la puissance des Modèles de
							Langage à Grande Échelle (LLMs). Ces assistants sont finement ajustés et personnalisés pour s'adapter à
							des cas d'utilisation spécifiques, garantissant ainsi une intégration transparente avec vos processus
							d'affaires.Elle conçue pour être facilement déployée sur n'importe quelle infrastructure, que ce soit sur
							le Cloud ou On-Premis.
						</div>
						<Styled.PrimaryButton
							onClick={() => window.open('https://gpt.nebulas.ai/', '_blank').focus()}
							type="primary"
							className="flex items-center"
						>
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
				</div>
				<div className="gap-4 flex flex-col-reverse sm:flex-row sm:flex sm:justify-between items-center space-y-4 sm:space-y-0">
					<div className="max-w-[556px]">
						<div className="fonts-small-blue">Formation & Coaching</div>
						<div className="text-[1.5rem] font-sans text-gray-950 font-bold mt-1 sm:mb-2 mb-1">
							Academy : Forgez l'excellence technologique avec nos formations sur mesure
						</div>
						<div className="fonts-body sm:mb-10 mb-2">
							Notre équipe est composée de data engineer, de data scientist, de spécialistes de l'IA et d'experts en
							cloud, chacun étant un expert dans son domaine, engagé à transmettre des connaissances pratiques et des
							compétences de pointe qui vous aideront à exceller dans un environnement technologique en constante
							évolution.
						</div>
						<Styled.PrimaryButton type="primary" onClick={() => navigate('/about_us')} className="flex items-center">
							<span className="ml-1.5">En savoir plus</span>
							<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M4.16663 10H15M10.8333 5L15.244 9.41074C15.5695 9.73618 15.5695 10.2638 15.244 10.5893L10.8333 15"
									stroke="black"
									strokeWidth="1.67"
									strokeLinecap="round"
								/>
							</svg>
						</Styled.PrimaryButton>
					</div>
					<div>
						<img
							src={academy}
							alt="service"
							className="sm:max-h-[360px] sm:max-w-[360px] max-h-[240px] max-w-[240px] w-full"
						/>
					</div>
				</div>
			</div>
			<TeamsContent />
		</Styled.Root>
	)
}

export default HomePage
