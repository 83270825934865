import { createSlice } from '@reduxjs/toolkit'

const homeSlice = createSlice({
	name: 'home',
	initialState: { isHomeReady: false, count: 0 },
	reducers: {
		startup(state) {},
		startupSuccess(state) {
			state.isHomeReady = true
		},
		startupFailure() {},
	},
})

export const { startupSuccess, startup, startupFailure } = homeSlice.actions

export default homeSlice.reducer
