import { Button, Checkbox, Form, Input, Upload, message } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { RiArrowLeftSLine, RiArrowRightLine, RiArrowRightSLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { RiAttachment2 } from 'react-icons/ri'
import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'

import Page from 'shared/components/page/Page'

import { useGetUUIDQuery, usePostCVMutation, usePostCandidateFormMutation } from 'shared/api/userApi'

import careers from 'modules/careers/careers.json'

import * as Styled from 'modules/home/components/__styles__/HomePage.styles'

const CareersDetailsPage = () => {
	const [messageApi, contextHolder] = message.useMessage()
	const [count, setCount] = useState(10)

	const [displayApply, setDisplayApply] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)

	useEffect(() => {
		if (isSuccess) {
			const timer = setInterval(() => {
				if (count > 0) {
					setCount((count) => count - 1)
				} else {
					clearInterval(timer)
					setDisplayApply(true)
					setIsSuccess(false)
				}
			}, 1000)

			return () => {
				clearInterval(timer)
			}
		}
	}, [isSuccess, count])

	const { data } = useGetUUIDQuery()
	const [postCV] = usePostCVMutation()
	const [postMetadata] = usePostCandidateFormMutation()

	const location = useLocation()

	const id = location.pathname.split('/')[2]
	const career = careers.data.find((item) => item.key === id)

	const getFile = (e) => {
		if (Array.isArray(e)) {
			return e
		}
		return e && e.fileList
	}

	const _onFinish = async (values) => {
		const formData = new FormData()
		formData.append('file', values?.cv?.[0]?.originFileObj)
		const cvResult = await postCV({ formData, uuid: data?.uuid })
		delete values.cv

		const result = await postMetadata({ metadata: values, uuid: data?.uuid })
		if (cvResult?.data?.success && result?.data?.success) {
			setIsSuccess(true)
		} else {
			messageApi.error(
				cvResult?.error?.data?.detail?.[0]?.msg || result?.error?.data?.detail?.[0]?.msg || 'Server Error!'
			)
		}
	}

	if (isSuccess) {
		return (
			<Page id="CareersPage">
				<div className="max-w-7xl layout mt-16 font-sans">
					<div className="bg-[#FAFBFC] p-8 mx-auto text-center max-w-[728px]">
						<RiCheckboxCircleFill className="w-12 h-12 text-[#40C1AA]" />
						<div className="fonts-h4 mt-8 mb-4">We’ve received your job application!</div>
						<div>
							You application reference is {data?.uuid}. We’ve sent an confirmation email to your mail address. We will
							contact you in the following days.
						</div>
						<div className="mt-8 text-primary-600">Thanks. Jumping over to the Careers page in {count} s ...</div>
						<Styled.SecondaryButton
							className="mt-8 mx-auto"
							onClick={() => {
								setDisplayApply(true)
								setIsSuccess(false)
							}}
						>
							Retour aux Offres d'Emploi
						</Styled.SecondaryButton>
					</div>
				</div>
			</Page>
		)
	}
	return (
		<Page id="CareersPage">
			{contextHolder}
			<div className="max-w-7xl layout">
				<div className="flex items-center space-x-2 sm:my-8 my-4">
					<RiArrowLeftSLine className="w-5 h-5 text-gray-950" />
					<Link to="/careers" className="fonts-body-medium">
						Retour aux Offres d'Emploi
					</Link>
				</div>
				{displayApply ? (
					<div className="space-y-8 max-w-5xl layout">
						<table className="w-full border-small">
							<tbody>
								<tr
									onClick={() => setDisplayApply(false)}
									className="cursor-pointer border border-solid border-[#EAEBF0]"
								>
									<td className="flex items-center justify-between py-5 sm:px-8 px-4">
										<div className="flex-row sm:flex items-center justify-between">
											<div className="mr-8 text-left">
												<div className="fonts-h5-gray">{career?.title}</div>
												<div className="space-x-4 text-gray-50 font-sans leading-6 font-medium">
													<span>{career?.location}</span>
													<span>{career?.type}</span>
												</div>
											</div>
											<div>
												<svg width="55" height="28" viewBox="0 0 55 28" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect width="55" height="28" rx="5" fill="#F7FAFE" />
													<path
														d="M21.3366 8.81818V19H19.9247L14.7493 11.5327H14.6548V19H13.1186V8.81818H14.5405L19.7209 16.2955H19.8153V8.81818H21.3366ZM26.6495 19.1541C25.8971 19.1541 25.2491 18.9934 24.7056 18.6719C24.1653 18.3471 23.7477 17.8913 23.4527 17.3047C23.1611 16.7147 23.0152 16.0237 23.0152 15.2315C23.0152 14.4493 23.1611 13.7599 23.4527 13.1634C23.7477 12.5668 24.1587 12.1011 24.6857 11.7663C25.216 11.4316 25.8358 11.2642 26.5451 11.2642C26.9759 11.2642 27.3935 11.3355 27.7979 11.478C28.2023 11.6205 28.5652 11.8442 28.8867 12.1491C29.2082 12.4541 29.4617 12.8501 29.6473 13.3374C29.8329 13.8213 29.9257 14.4096 29.9257 15.1023V15.6293H23.8554V14.5156H28.4691C28.4691 14.1245 28.3895 13.7782 28.2304 13.4766C28.0713 13.1716 27.8476 12.9313 27.5593 12.7557C27.2742 12.58 26.9395 12.4922 26.555 12.4922C26.1374 12.4922 25.7728 12.5949 25.4613 12.8004C25.153 13.0026 24.9144 13.2678 24.7453 13.5959C24.5796 13.9207 24.4968 14.2737 24.4968 14.6548V15.5249C24.4968 16.0353 24.5863 16.4695 24.7652 16.8274C24.9475 17.1854 25.2011 17.4588 25.5259 17.6477C25.8507 17.8333 26.2302 17.9261 26.6644 17.9261C26.9461 17.9261 27.203 17.8864 27.435 17.8068C27.667 17.724 27.8675 17.6013 28.0365 17.4389C28.2056 17.2765 28.3348 17.076 28.4243 16.8374L29.8313 17.0909C29.7186 17.5052 29.5164 17.8681 29.2248 18.1797C28.9364 18.4879 28.5735 18.7282 28.136 18.9006C27.7018 19.0696 27.2063 19.1541 26.6495 19.1541ZM33.1169 19L30.8698 11.3636H32.406L33.9024 16.9716H33.977L35.4784 11.3636H37.0147L38.5061 16.9467H38.5807L40.0672 11.3636H41.6034L39.3613 19H37.8449L36.2938 13.4865H36.1794L34.6283 19H33.1169Z"
														fill="#3B72DF"
													/>
												</svg>
											</div>
										</div>
										<RiArrowRightLine className="w-5 h-5 text-gray-950" />
									</td>
								</tr>
							</tbody>
						</table>
						<div className="fonts-h4">Complete your application</div>
						<Styled.Form layout="vertical" onFinish={_onFinish}>
							<Form.Item
								label="Resume/CV"
								valuePropName="fileList"
								name="cv"
								getValueFromEvent={getFile}
								required
								rules={[{ required: true, message: 'Please upload your CV!' }]}
							>
								<Upload
									beforeUpload={() => false}
									accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
									listType="picture"
									maxCount={1}
								>
									<Button icon={<RiAttachment2 />}>Attach Resume/CV</Button>
								</Upload>
							</Form.Item>
							<Form.Item
								rules={[{ required: true, message: 'Please input your name!' }]}
								label="Full name"
								name="full_name"
								required
							>
								<Input />
							</Form.Item>
							<Form.Item
								rules={[{ required: true, message: 'Please input your Email!' }]}
								label="Email"
								name="email"
								required
							>
								<Input />
							</Form.Item>
							<Form.Item
								rules={[{ required: true, message: 'Please input your phone number!' }]}
								label="Phone"
								name="phone"
								required
							>
								<Input />
							</Form.Item>
							<Form.Item label="Current company" name="company">
								<Input />
							</Form.Item>
							<Form.Item label="Linkedin link" name="linkedin">
								<Input />
							</Form.Item>
							<Form.Item label="Github link" name="github">
								<Input />
							</Form.Item>
							<Form.Item label="Portfolio link" name="portfolio">
								<Input />
							</Form.Item>
							<Form.Item label="Other link" name="other">
								<Input />
							</Form.Item>
							<Form.Item label="Cover Letter" name="cover_letter">
								<Input.TextArea rows={5} />
							</Form.Item>
							<Form.Item
								name="agreement"
								valuePropName="checked"
								rules={[
									{
										validator: (_, value) =>
											value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
									},
								]}
							>
								<Checkbox>
									<span className="fonts-black">
										I confirm to share my CV and personal info to Gnomon Digital for applying this position. Gnomon
										digital won’t use and restore your personal data for other usage.
									</span>
								</Checkbox>
							</Form.Item>
							<Form.Item>
								<Styled.SecondaryButton htmlType="submit" className="mx-auto">
									Submit Your Application
									<RiArrowRightSLine className="w-5 h-5 text-white" />
								</Styled.SecondaryButton>
							</Form.Item>
						</Styled.Form>
					</div>
				) : (
					<div>
						<div className="fonts-h2">{career?.title}</div>
						<div className="space-x-4 text-gray-50 font-sans leading-6 font-medium mb-8">
							<span>{career?.location}</span>
							<span>{career?.type}</span>
						</div>
						<Styled.SecondaryButton className="my-8" onClick={() => setDisplayApply(true)}>
							Apply Now
							<RiArrowRightSLine className="w-5 h-5 text-white" />
						</Styled.SecondaryButton>
						<ReactMarkdown children={career?.description} rehypePlugins={[rehypeRaw]} className="markdown" />
						<Styled.SecondaryButton className="my-8" onClick={() => setDisplayApply(true)}>
							Apply Now
							<RiArrowRightSLine className="w-5 h-5 text-white" />
						</Styled.SecondaryButton>
					</div>
				)}
			</div>
		</Page>
	)
}

export default CareersDetailsPage
