import { Link } from 'react-router-dom'
import { motion, useInView } from 'framer-motion'
import React, { useRef } from 'react'

import black_logo from 'assets/svg/logo-black.svg'

import * as Styled from 'shared/components/layout/__styles__/Footer.styles'

const Footer = () => {
	const footRef = useRef(null)
	const isInView = useInView(footRef)

	const footerItems = [
		{
			title: 'Produits & Services',
			children: [
				// { title: 'Consulting Service', link: '/consulting_service' },
				{ title: 'Nebulas.ai', link: 'https://gpt.nebulas.ai/' },
				{ title: 'Académie', link: '/' },
			],
		},
		{
			title: 'Carrières',
			children: [{ title: 'Postes à Pourvoir', link: '/careers' }],
		},
		{
			title: 'Contact',
			children: [{ title: 'À Propos de Nous', link: '/about_us' }],
		},
	]

	return (
		<Styled.Root role="banner" className="bg-white p-4">
			<div className="max-w-7xl w-full mx-auto py-2 sm:py-8" ref={footRef}>
				<motion.ul
					className="font-sans leading-6 grid grid-cols-6 gap-8"
					animate={isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
					initial={{ opacity: 0, y: 50 }}
					transition={{ duration: 0.5 }}
				>
					<li className="sm:col-span-2 col-span-6">
						<div className="space-y-4 max-w-xs">
							<img alt="Gnomon Digital Logo" src={black_logo} />
							<div className="fonts-body">Suivez-Nous</div>
							<div className="flex items-center space-x-2">
								<a href="https://www.linkedin.com/company/gnomon-digital/about/">
									<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24">
										<path d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.105,4,24,4z M10.954,22h-2.95 v-9.492h2.95V22z M9.449,11.151c-0.951,0-1.72-0.771-1.72-1.72c0-0.949,0.77-1.719,1.72-1.719c0.948,0,1.719,0.771,1.719,1.719 C11.168,10.38,10.397,11.151,9.449,11.151z M22.004,22h-2.948v-4.616c0-1.101-0.02-2.517-1.533-2.517 c-1.535,0-1.771,1.199-1.771,2.437V22h-2.948v-9.492h2.83v1.297h0.04c0.394-0.746,1.356-1.533,2.791-1.533 c2.987,0,3.539,1.966,3.539,4.522V22z"></path>
									</svg>
								</a>
								<a className="flex items-center space-x-2" href="mailto:contact@gnomondigital.com">
									<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M2.97656 16.7349C2.64323 16.7349 2.35156 16.6099 2.10156 16.3599C1.85156 16.1099 1.72656 15.8183 1.72656 15.4849V4.65161C1.72656 4.31828 1.85156 4.02661 2.10156 3.77661C2.35156 3.52661 2.64323 3.40161 2.97656 3.40161H17.1432C17.4766 3.40161 17.7682 3.52661 18.0182 3.77661C18.2682 4.02661 18.3932 4.31828 18.3932 4.65161V15.4849C18.3932 15.8183 18.2682 16.1099 18.0182 16.3599C17.7682 16.6099 17.4766 16.7349 17.1432 16.7349H2.97656ZM10.0599 10.4433L2.97656 5.79744V15.4849H17.1432V5.79744L10.0599 10.4433ZM10.0599 9.19328L17.0599 4.65161H3.08073L10.0599 9.19328ZM2.97656 5.79744V4.65161V15.4849V5.79744Z"
											fill="Black"
										/>
									</svg>
								</a>
								<a href="https://github.com/gnomondigital">
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clipPath="url(#clip0_767_9170)">
											<path
												d="M12 2C6.475 2 2 6.475 2 12C1.99887 14.0993 2.65882 16.1456 3.88622 17.8487C5.11362 19.5517 6.84615 20.8251 8.838 21.488C9.338 21.575 9.525 21.275 9.525 21.012C9.525 20.775 9.512 19.988 9.512 19.15C7 19.613 6.35 18.538 6.15 17.975C6.037 17.687 5.55 16.8 5.125 16.562C4.775 16.375 4.275 15.912 5.112 15.9C5.9 15.887 6.462 16.625 6.65 16.925C7.55 18.437 8.988 18.012 9.562 17.75C9.65 17.1 9.912 16.663 10.2 16.413C7.975 16.163 5.65 15.3 5.65 11.475C5.65 10.387 6.037 9.488 6.675 8.787C6.575 8.537 6.225 7.512 6.775 6.137C6.775 6.137 7.612 5.875 9.525 7.163C10.3391 6.93706 11.1802 6.82334 12.025 6.825C12.875 6.825 13.725 6.937 14.525 7.162C16.437 5.862 17.275 6.138 17.275 6.138C17.825 7.513 17.475 8.538 17.375 8.788C18.012 9.488 18.4 10.375 18.4 11.475C18.4 15.313 16.063 16.163 13.838 16.413C14.2 16.725 14.513 17.325 14.513 18.263C14.513 19.6 14.5 20.675 14.5 21.013C14.5 21.275 14.688 21.587 15.188 21.487C17.173 20.8168 18.8979 19.541 20.1199 17.8392C21.3419 16.1373 21.9994 14.0951 22 12C22 6.475 17.525 2 12 2Z"
												fill="black"
											/>
										</g>
										<defs>
											<clipPath id="clip0_767_9170">
												<rect width="24" height="24" fill="white" />
											</clipPath>
										</defs>
									</svg>
								</a>
							</div>
						</div>
					</li>
					{footerItems.map(({ title, children }, i) => (
						<li key={i} className="space-y-4 sm:col-span-1 col-span-3 sm:mb-8 mb-4">
							<div className="font-semibold text-gray-700 mb-3">{title}</div>
							{children.map((item, j) => (
								<Link to={item.link} key={j} target={'_blank'} className="font-medium text-gray-50 block">
									{item.title}
								</Link>
							))}
						</li>
					))}

					<li className="col-span-6">
						<div className="grid grid-cols-10 sm:gap-8 gap-2 sm:text-center text-left">
							<div className="sm:col-span-4 col-span-10 fonts-small">© 2023 GNOMON DIGITAL. All rights reserved.</div>
							<Link to="/terms" className="sm:col-span-2 col-span-5 font-semibold fonts-small">
								Terms Of Service
							</Link>
							<Link to="/privacy_policy" className="sm:col-span-2 col-span-5 font-semibold fonts-small">
								Privacy Policy
							</Link>
							<Link to="/disclosure" className="sm:col-span-2 col-span-5 font-semibold fonts-small">
								Responsible Disclosure
							</Link>
						</div>
					</li>
				</motion.ul>
			</div>
		</Styled.Root>
	)
}

export default Footer
