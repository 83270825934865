import { createSlice } from '@reduxjs/toolkit'

const rootSlice = createSlice({
	name: 'root',
	initialState: { isAppReady: false, token: null, user: null },
	reducers: {
		startup() {},
		startupSuccess(state) {
			state.isAppReady = true
		},
		startupFailure() {},
	},
})

export const { startupSuccess, startup, startupFailure } = rootSlice.actions

export default rootSlice.reducer
